/* Made with love by Mutiullah Samim*/

@import url('https://fonts.googleapis.com/css?family=Numans');

html, body {
    background-image: url("./images/cropped-blue021.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    font-family: Helvetica, sans-serif;
}

.Login {
    height: 100%;
    display: flex;
    align-content: center;
    font-family: 'Numans', sans-serif !important;
    position: relative;
}

.ForgotPassword.container {
    height: 100%;
    align-content: center;
}

.ForgotPassword .card {
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.ForgotPassword a {
    color: lightgrey;
    text-decoration: underline;
}

.Login .card {
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.Login .card-header h3 {
    color: white;
}

.Login a {
    color: lightgrey;
    text-decoration: underline;
}

.social_icon span {
    font-size: 60px;
    margin-left: 10px;
    color: #FFC312;
}

.social_icon span:hover {
    color: white;
    cursor: pointer;
}

.social_icon {
    position: absolute;
    right: 20px;
    top: -45px;
}

.input-group-prepend span {
    width: 50px;
    background-color: #FFC312;
    color: black;
    border: 0 !important;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;

}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

button.login_btn {
    color: #606060;
    background-color: #a0a0a0;
    width: 100px;
}

button.login_btn:hover {
    color: #606060;
    background-color: #a0a0a0;
    width: 100px;
}

.login_btn {
    color: black;
    background-color: #FFC312;
    width: 100px;
}

.login_btn:hover {
    color: black;
    background-color: white;
}

.reset-password-btn {
    color: black;
    background-color: #FFC312;
    width: 200px;
}

.reset-password-btn:hover {
    color: black;
    background-color: white;
}

.links {
    color: white;
}

.links a {
    margin-left: 4px;
}