$black: #000000;
$pale-cerulean: #9BB8CF;
$cool-grey: #8589A0;
$asparagus: #90a375;
$plum: #804387;
$french-sky-blue: #6BB2F9;

$bgcolor: $pale-cerulean;
$highlight: $plum;
$border: $asparagus;
$button: $asparagus;

html {
  height: 100%;
  margin: 0;
}

a {
  color: $black;
}

a:visited {
  color: $black;
}

a:visited.MuiButton-contained {
  color: white;
}

a:visited.MuiFab-root {
  color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.subHeader {
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

header {
  background-color: transparent;
}

.pagePaper {
}

.bg {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.modalIcon {
  font-size: 24pt;
  margin-right: 10px;
  margin-top: -10px;
  float: left;
}

.data-container {
  margin: 10px auto;
  min-width: 80%;
  height: 100%;
}

.list-container {
  height: 100%;
}

.program-school-list {
  margin-bottom: 10px;
}

.optionsCell {
  a {
    color: black;
    position: relative;
  }
  a:hover {
    color: #606060;
  }

  .deleteIcon {
    color: #c00000;
  }

  .deleteIcon:hover {
    color: red;
  }
}

.go {
  color: #00aa00
}

.go a {
  color: #00aa00
}

.go a:visited {
  color: #007700
}

.stop {
  color: red;
}

.pause {
  color: orange;
}

td.notInCycle {
  color: #808080;
}

button.go {
  background-color: #00aa00;
  color: #ffffff;
  margin-right: 5px;
  white-space: nowrap;
  text-transform: none;
}

button.go:hover {
  background-color: #009900;
  color: #ffffff;
  margin-right: 5px;
  white-space: nowrap;
  text-transform: none;
}

button.stop {
  background-color: #aa0000;
  color: #ffffff;
  font-weight: bold;
  text-transform: none;
  margin-right: 5px;
  white-space: nowrap;
}

button.stop:hover {
  background-color: darkred;
  color: #ffffff;
  font-weight: bold;
  text-transform: none;
  margin-right: 5px;
  white-space: nowrap;
}

button.done {
  background-color: #aaaaaa;
  color: #e0e0e0;
  font-weight: bold;
  margin-right: 5px;
  white-space: nowrap;
}

button.done:hover {
  background-color: #999;
  color: #e0e0e0;
  font-weight: bold;
  margin-right: 5px;
  white-space: nowrap;
}

.wait {
  color: orange;
}


.openingsForm {
  padding: 16px 0px 0px 5px;
  display: inline-block;

  span {
    padding: 2px;
  }
}

button.mini {
  text-transform: none;
  text-decoration: none;
  padding: 1px;
  margin: 5px;
  font-size: 0.8rem;
}

.scrollY {
  overflow-y: scroll;
}

.optionsEditContainer {
  max-height: 200px;
  width: 400px;
}

.warning-box {
  color: red;
  padding: 10px;
  margin: 5px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 5px;
  font-weight: bold;
}

button a {
  color: #000000;
}

button a:visited {
  color: #000000;
}

button a:hover {
  color: #444444;
}

td.questionnaireLabel {
  width: 300px;
}

.text-danger {
  color: #dc3545 !important;
}

.StripeElement {
  box-sizing: border-box;
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(0,128,0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0,128,0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,128,0, 0);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}